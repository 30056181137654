<script setup lang="ts">
import { Image } from '@/components';
import { useOfficialStyle } from './use-official-style.ts';
import { useRouter } from 'vue-router';
import type { FilterResult } from '@/api/cloud-resource';
import { showToast } from 'vant';

const { results } = useOfficialStyle();

const router = useRouter();
const onClick = async (item: FilterResult) => {
  const sco = item.sco[0];
  if (!sco) {
    showToast('未找到资源');
    return;
  }

  let query = {};
  const regex = /^(?:https?:\/\/)?(?:[\w-]+\.)?cloudknit\.cn(?:\/project)?\/(\w+)$/;
  const match = sco.match(regex);
  if (match && match[1]) {
    query = {
      scoId: match[1]
    };
  } else {
    query = {
      sco,
      scoId: item.id
    };
  }

  const lastRoute = JSON.parse(localStorage.getItem('lastRoute') || '{}');
  await router.replace({
    name: 'fabric', params: {
      id: 'default',
      ...lastRoute.params
    },
    query:{
      ...lastRoute.query,
      ...query
    }
  });
};

</script>

<template>
  <div
    :class="$style.card"
    v-for="item of results"
    :key="item.id"
    @click="()=>onClick(item)"
  >
    <Image
      :src="item.image[0]||''"
      lazy-load
      :height="200"
      fit="scale-down"
    />
    <div :class="$style.name">
      {{ item.name }}
    </div>
  </div>
</template>

<style module>
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.name {
  padding: 0 10px;
}
</style>

