import { db } from './db.ts';
import type { FavoriteStore } from './typing.ts';

function tokenize(favorite: Omit<FavoriteStore, 'terms' | 'date'>) {
  const words = new Set<string>();
  words.add(favorite.id);
  favorite.memo && words.add(favorite.memo);
  favorite.properties.forEach((prop) => {
    prop.value && words.add(prop.value);
  });
  return Array.from(words);
}

export async function saveFavorite(favorite: Omit<FavoriteStore, 'terms' | 'date'>) {
  await db.put('favorites', { ...favorite, terms: tokenize(favorite), date: Date.now() });
}

export async function getFavoriteByID(id: string) {
  return await db.get('favorites', id);
}

export async function getFavorites(count: number = 10, enterpriseId = '', date?: [number, number]) {
  const queryRange = date && IDBKeyRange.bound(date[0], date[1]);
  const data = await db.getAllFromIndex('favorites', 'by-date', count, 'prev', queryRange);
  if (enterpriseId) {
    return data!.filter(item => item.enterpriseId === enterpriseId);
  }
  return data;
}

export async function searchFavorites(keyword: string = '', count: number = 10, enterpriseId = '', date?: [number, number]) {
  const queryRange = date && IDBKeyRange.bound(date[0], date[1]);
  const data = await db.searchByTerms('favorites', 'fulltext', keyword, count, queryRange);
  if (enterpriseId) {
    return data!.filter(item => item.enterpriseId === enterpriseId);
  }
  return data;
}

export async function deleteFavorites(ids: string[]) {
  await Promise.all(ids.map(id => db.delete('favorites', id)));
}

export async function getEnterprises() {
  const dbInstance = await db.getDbInstance();

  let cursor = await dbInstance.transaction('favorites')
    .store.index('by-enterprise')
    .openCursor();

  const map = new Map<string, string>();
  while (cursor) {
    const value = cursor.value;
    if (value.enterpriseId) {
      map.set(value.enterpriseId, value?.enterpriseName || '');
      map.set(value.enterpriseId, value?.enterpriseName || '');
    }
    cursor = await cursor.continue();
  }
  const obj = Object.fromEntries(map);
  return Object.entries(obj).map(([key, value]) => ({ id: key, name: value }));
}

