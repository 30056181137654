import axios from 'axios';

const baseUrl = 'https://knit-oss-mgn-rmkxbquhlf.cn-hangzhou.fcapp.run';

interface DataQuery {
  applyId: number;
  fieldName: string;
}

interface FileListParams extends DataQuery {
  enterpriseId: string;
}

export interface QingflowFile {
  name: string;
  url: string;
}

export async function getFileList(reqParam: FileListParams): Promise<QingflowFile[]> {
  try {
    const response = await axios.post(`${baseUrl}/v1/file/list`, {
      ...reqParam
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

interface ExhibitionListParams {
  enterpriseId: string;
  phone?: string;
}

export interface Exhibition {
  'id': string;
  'externalId': string;
  'enterpriseId': string;
  'name': string;
  'status': number;
  'coverImageData': DataQuery;
  'openTime': string;
  'closeTime': string;
  'description': DataQuery;
  'restrictAccess': false,
  'createdAt': string;
  'updatedAt': string;
  'syncTimestamp': string;
  'customerAccess': []
}

export async function getEnterpriseExhibitionList(reqParam: ExhibitionListParams): Promise<Exhibition[]> {
  try {
    const response = await axios.get(`${baseUrl}/v1/exhibition/${reqParam.enterpriseId}/list`, {
      params: {
        phone: reqParam.phone
      }
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

interface ExhibitionDataListParams {
  externalId: string;
  phone?: string;
  enterpriseId: string;
}

interface DisplayData {
  files: {
    image: DataQuery;
    '3dfile': DataQuery[];
    other: DataQuery[];
  };
  render: {
    key: string;
    name: string;
    is_other: boolean;
  }[];
  metadata: {
    [key: string]: any;
    'other': {
      [key: string]: any;
    }
  };
}

export interface ExhibitionData {
  'id': string;
  'type': 0 | 1;
  'expiresAt': string | null;
  'enterpriseId': string;
  'qrCode': string;
  'is_delete': 0 | 1;
  'is_ready': 0 | 1;
  'displayData': DisplayData;
}

export async function getExhibitionDataList(reqParam: ExhibitionDataListParams): Promise<ExhibitionData[]> {
  try {
    const response = await axios.post(`${baseUrl}/v1/exhibitionData/list`, {
      ...reqParam
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export interface DataItem {
  id: string;
  type: 0 | 1;
  enterpriseId: string;
  expiresAt: string;
  property: DisplayData;
  qrCode?: string;
}

export async function getDataDetail(dataid: string): Promise<DataItem> {
  try {
    const response = await axios.get(`${baseUrl}/v1/data/detail/${dataid}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export async function getDataIdFromCode(url: string): Promise<DataItem> {
  try {
    const response = await axios.post(`${baseUrl}/v1/data/detail`, { code: url });
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

interface YarnParams {
  enterpriseId: string;
  dataId: string;
}

export interface YarnData {
  video: DataQuery;
  image: DataQuery;
  coverImage: DataQuery;
  property: Record<string, string>;
}

export async function getYarnData(reqParam: YarnParams): Promise<YarnData> {
  try {
    const response = await axios.get(`${baseUrl}/v1/yarn/${reqParam.enterpriseId}/${reqParam.dataId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

interface RecommendParams {
  enterpriseId: string;
  dataId: string;
}


export interface RecommendDataItem {
  id: string;
  type: 0 | 1;
  expiresAt: string;
  displayData: DisplayData;
}
export async function getRecommendData(reqParams: RecommendParams): Promise<{ total: number; items: RecommendDataItem[] }> {
  try {
    const response = await axios.post(`${baseUrl}/v1/yarn/recommend`, { ...reqParams });
    return response.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}
