<script setup lang="ts">
import { $t } from '@repo/locales';
import { CellGroup, Field } from 'vant';
import { SvgOpenInNewIcon } from '@repo/icons';
import { useParamStore } from '@/store/param.ts';
import { useRelationStore } from '@repo/stores';
import { computed } from 'vue';
import { useFabricStore } from '@/store/fabric.ts';
import { isAbsoluteURL } from '@repo/utils';
import type { Shape } from '@repo/style3d';

const fabricStore = useFabricStore();

const properties = computed(() => {
  return fabricStore.fabricProperty.map(item => {
    let value = item.value;
    if (item.key === 'fabricWeightPos') {
      value = item.value + ' g/(10x10cm)';
    } else if (item.key == 'fabricUnitPrice') {
      value = item.value + ' ' + $t('common.rmb');
    }
    return {
      ...item,
      value,
      isLink: isAbsoluteURL(value || '')
    };
  }) as Record<string, any>[];
});

const onClickField = (item: Record<string, any>) => {
  if (item.isLink) {
    location.href = item.value;
  }
};

function shapeTotalSquare(shapeList: Shape[]) {
  return shapeList.reduce((acc, item) => {
    acc += (item.square || 0);
    return acc;
  }, 0.0);

}

const paramStore = useParamStore();
const relationStore = useRelationStore();
const isDefaultHanger = computed(() => paramStore.resourceParam.scoResource.type === 'cloudknit' && paramStore.resourceParam.scoResource.id === 'fabric_hanger');

const styleWeightProperty = computed(() => fabricStore.styleProperty.find(item => item.key === 'styleWeight'));

const styleWeight = computed(() => {
  if (!styleWeightProperty.value) {
    return 0;
  }
  const shapeList = relationStore?.shapeList || [];

  let total = 0;
  const mainShapeList = shapeList.filter(item => item.type === 'main');
  const mainSquare = shapeTotalSquare(mainShapeList);
  total += (fabricStore.fabricInfo?.info?.gramWeight || 0) * mainSquare;

  const collarShapeList = shapeList.filter(item => item.type === 'collar');
  const collarSquare = shapeTotalSquare(collarShapeList);
  total += (fabricStore.fabricInfo?.info?.gramWeight_collar || 0) * collarSquare;

  const hemShapeList = shapeList.filter(item => item.type === 'hem');
  const hemSquare = shapeTotalSquare(hemShapeList);
  total += (fabricStore.fabricInfo?.info?.gramWeight_luowen || 0) * hemSquare;

  const placketShapeList = shapeList.filter(item => item.type === 'placket');
  const placketSquare = shapeTotalSquare(placketShapeList);
  total += (fabricStore.fabricInfo?.info?.gramWeight_menjing || 0) * placketSquare;

  return total;
});

const estimationPriceProperty = computed(() => fabricStore.styleProperty.find(item => item.key === 'estimationPrice'));
const estimationPrice = computed(() => {
  if (!estimationPriceProperty.value) {
    return 0;
  }
  return styleWeight.value / 1000 * (fabricStore.fabricInfo?.info?.unitPrice || 0);
});
</script>

<template>
  <CellGroup>
    <Field
      v-if="!isDefaultHanger && styleWeightProperty"
      :label="styleWeightProperty.name"
      :model-value="`` + styleWeight.toFixed(0) +' '+ 'g'"
      readonly
    />
    <Field
      v-if="!isDefaultHanger && estimationPriceProperty"
      :label="estimationPriceProperty.name"
      :model-value="`` + estimationPrice.toFixed(1) +' '+ $t('common.rmb')"
      readonly
    />
    <Field
      v-for="item of properties"
      :key="item.name"
      :label="item.name"
      :model-value="item.value"
      readonly
      @click="()=>onClickField(item)"
    >
      <template #button>
        <SvgOpenInNewIcon v-if="item.isLink" />
      </template>
    </Field>
  </CellGroup>
</template>

<style scoped>

</style>
