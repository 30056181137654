<script setup lang="ts">
import { Button, CellGroup, Field, Form, Radio, RadioGroup, showFailToast, showSuccessToast, Stepper } from 'vant';
import { ref } from 'vue';
import axios from 'axios';
import { $t } from '@repo/locales';
import { QrScan } from '@/components';
import { useRoute, useRouter } from 'vue-router';

const fabricCode = ref('');
const fabricName = ref('');
const times = ref(10);
const unlimit = ref(false);

const onSubmit = async () => {
  const savedUser = localStorage.getItem('user');

  if (!savedUser) {
    showFailToast('enterprise_id 为空');
    return;
  }
  const { enterprise_id } = JSON.parse(savedUser);

  const resp = await axios.post<any>('https://knit-oss-mgn-rmkxbquhlf.cn-hangzhou.fcapp.run/v1/ai/setFabric', {
    'fabricCode': fabricCode.value,
    'fabricName': fabricName.value,
    'times': times.value,
    'enterpriseId': enterprise_id,
    'unlimit': unlimit.value
  });

  if (resp.data.state === 0) {
    showFailToast(resp.data.msg);
    return;
  }

  showSuccessToast('设置试衣次数成功');
  fabricCode.value = '';
};

const onScanSuccess = async (text: string) => {
  fabricCode.value = text;
};

const router = useRouter();
const route = useRoute();
const fromPath = route.query.from as string;
const query = JSON.parse(decodeURIComponent(route.query?.query as string || '{}'));

const onBack = async () => {
  await router.replace({
    path: fromPath || '/tryon',
    query: { ...query }
  });
};
</script>

<template>
  <div :class="$style.container">
    <Form @submit="onSubmit" :class="$style.form">
      <CellGroup inset>
        <Field
          center
          v-model="fabricCode"
          name="面料二维码"
          label="面料二维码"
          placeholder="面料二维码"
          :rules="[{ required: true, message: '请扫码' }]"
        >
          <template #button>
            <QrScan :validator="()=>true" :callback="onScanSuccess">
              <template #scan-icon>
                <Button type="primary" size="small">
                  {{ $t('tabbar.scan') }}
                </Button>
              </template>
            </QrScan>
          </template>
        </Field>
        <Field
          :value="fabricName"
          name="面料名称"
          label="面料名称"
          placeholder="面料名称"
          :readonly="true"
        />
        <Field
          name="可用次数"
          label="可用次数"
          placeholder="可用名称"
        >
          <template #input>
            <Stepper v-model="times" />
          </template>
        </Field>
        <Field
          name="不限制访客"
          label="不限制访客"
          placeholder="不限制访客"
        >
          <template #input>
            <RadioGroup v-model="unlimit" direction="horizontal">
              <Radio :name="true">是</Radio>
              <Radio :name="false">否</Radio>
            </RadioGroup>
          </template>
        </Field>
      </CellGroup>

      <div style="padding-top: 30px; width: 100%;display: flex; gap: 16px;">
        <Button block @click="onBack">
          返回
        </Button>
        <Button block type="primary" native-type="submit">
          提交
        </Button>
      </div>
    </Form>

  </div>
</template>

<style module>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  padding: 10px;
}

</style>
