<script setup lang="ts">
import { FavoriteEdit, PopoverButtons, ToolButton } from '@/components';
import { FabricEditor, ResolutionToggle } from '.';
import { useFabricStore } from '@/store/fabric.ts';
import { useRoute, useRouter } from 'vue-router';
import { base64ToFile, generateRandomUUID } from '@repo/utils';
import { showToast } from 'vant';
import { useStyle3d } from '@repo/style3d';
import { uploadFileViaStsToken } from '@/api';
import { $t } from '@/locales';
import { SvgEllipsisHIcon, SvgStarOutlineIcon } from '@repo/icons';
import { ref } from 'vue';

const fabricStore = useFabricStore();

const router = useRouter();
const route = useRoute();

const style3d = useStyle3d();
const onClickTryon = async () => {
  if (route.params?.id === 'fabric_hanger') {
    showToast($t('message.select-sco'));
    return;
  }
  const data = await style3d.getScreenshot();
  const fabricId = fabricStore?.fabricInfo?.fabricId || '';
  const originUrl = fabricStore?.fabricInfo?.originUrl || '';

  const file = base64ToFile(data, 'screenshot.png');
  const key = generateRandomUUID();
  const date = new Date();
  const path = `/aitryon/${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}/${key}/screenshot.png`;

  const uploadedUrl = await uploadFileViaStsToken(path, file);
  await router.replace({
    name: 'tryon',
    query: {
      cloth: uploadedUrl,
      fabricId,
      originUrl
    }
  });
};

const onClickTailor = async () => {
  await router.replace({
    name: 'tailor',
    query: {}
  });
};

const showEditor = ref(false);
const onClickEditor = () => {
  showEditor.value = true;
};
</script>

<template>
  <PopoverButtons dark placement="left">
    <template #reference>
      <ToolButton>
        <SvgEllipsisHIcon />
      </ToolButton>
    </template>
    <ResolutionToggle />
    <div v-show="false">
      <ToolButton>
        {{ $t('common.edit') }}
      </ToolButton>
    </div>
    <div>
      <ToolButton @click="onClickTryon">
        {{ $t('toolbar.outfit') }}
      </ToolButton>
    </div>
    <!-- 暂时隐藏 -->
    <div v-if="false">
      <ToolButton @click="onClickTailor">
        {{ $t('toolbar.tailor') }}
      </ToolButton>
    </div>
    <div>
      <ToolButton @click="onClickEditor">
        编辑
      </ToolButton>
      <FabricEditor v-model:show="showEditor" />
    </div>
  </PopoverButtons>
  <FavoriteEdit v-bind="fabricStore.fabricInfo">
    <ToolButton>
      <SvgStarOutlineIcon style="width: 20px;height:20px;" />
    </ToolButton>
  </FavoriteEdit>
</template>

<style module>

</style>
