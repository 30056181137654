<script setup lang="ts">
import { SvgKeyboardArrowDown, SvgKeyboardArrowLeft, SvgKeyboardArrowRight, SvgKeyboardArrowUp } from '@repo/icons';
import { type DPM, useStyle3d } from '@repo/style3d';
import { useRelationStore, useSelectionStore } from '@repo/stores';

const { getStyle3dSDKInstance, getFabricState } = useStyle3d();

function calculateOffsetPercentage(dpm: DPM, width: number, height: number) {
  const xOffsetPixes = dpm.x / 1000;
  const yOffsetPixes = dpm.y / 1000;

  const xOffsetPercentage = xOffsetPixes / width;
  const yOffsetPercentage = yOffsetPixes / height;

  return {
    x: xOffsetPercentage,
    y: yOffsetPercentage
  };
}

const selectionStore = useSelectionStore();
const relationStore = useRelationStore();

type Direction = 'left' | 'right' | 'up' | 'down';

const onMove = async (dir: Direction) => {
  const style3d = getStyle3dSDKInstance()!;
  const found = relationStore.shapeList.find(item => item.id === selectionStore.selectedFabricId);
  if (!found) {
    return;
  }
  const { x, y } = calculateOffsetPercentage(found.dpm || { x: 100, y: 100 }, found.width || 100, found.height || 100);

  const { offsetX, offsetY } = await getFabricState();

  switch (dir) {
    case 'left': {
      let newValue = offsetX + x;
      if (newValue > 1.0) {
        newValue = newValue - 1;
      }
      await style3d.setFabricOffsetX(newValue);
      break;
    }
    case 'right': {
      let newValue = offsetX - x;
      if (newValue < 0.0) {
        newValue = 1 - newValue;
      }
      await style3d.setFabricOffsetX(newValue);
      break;
    }
    case 'up': {
      let newValue = offsetY - y;
      if (newValue < 0.0) {
        newValue = 1 - newValue;
      }
      await style3d.setFabricOffsetY(newValue);
      break;
    }
    case 'down': {
      let newValue = offsetY + y;
      if (newValue > 1.0) {
        newValue = newValue - 1;
      }
      await style3d.setFabricOffsetY(newValue);
      break;
    }
  }
};

</script>

<template>
  <div style="display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;">
    <div :class="$style.arrowContainer">
      <div />
      <div :class="$style.btn">
        <SvgKeyboardArrowUp :class="$style.icon" @click="()=>onMove('up')" />
      </div>
      <div />
      <div :class="$style.btn">
        <SvgKeyboardArrowLeft :class="$style.icon" @click="()=>onMove('left')" />
      </div>
      <div :class="$style.btn">
        <SvgKeyboardArrowDown :class="$style.icon" @click="()=>onMove('down')" />
      </div>
      <div :class="$style.btn">
        <SvgKeyboardArrowRight :class="$style.icon" @click="()=>onMove('right')" />
      </div>
    </div>
  </div>
</template>

<style module>
.arrowContainer {
  padding: 10px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 16px;
  column-gap: 30px;
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  border: 1px solid var(--van-gray-4);
  border-radius: 5px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  color: var(--van-button-default-color);
}
</style>
