<script setup lang="ts">
import { useRoute } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import { type DataItem, getDataDetail, getFileList, type QingflowFile } from '@/api/exhibition';
import { CellGroup, Field } from 'vant';
import { Tabbar } from './components/tabbar';
import { Carousels } from '@/components/carousels';
import { getFileType } from '@repo/utils';

const route = useRoute();
const dataId = route.params.dataid as string;

const data = ref<DataItem>();
const fileList = ref<(QingflowFile & { type: 'image' | 'video' | 'unknown' })[]>([]);

watch(() => data.value, async (data) => {
  if (data) {
    const query = data.property.files.image;
    const files = await getFileList({ enterpriseId: data.enterpriseId, ...query });
    fileList.value = [...files.map(item => ({ ...item, type: getFileType(item.name) }))];
  }
});

const rows = computed(() => data.value?.property.render.map(item => {
  if (item.is_other) {
    return { key: item.key, label: item.name, value: data.value?.property.metadata.other[item.key] };
  } else {
    return { key: item.key, label: item.name, value: data.value?.property.metadata[item.key] };
  }
}));

onMounted(async () => {
  data.value = await getDataDetail(dataId);
});

</script>

<template>
  <div :class="$style.container">
    <div :class="$style.swiper">
      <Carousels :height="300" :file-list="fileList" />
    </div>
    <div>
      <CellGroup>
        <Field
          v-for="r of rows"
          :key="r.key"
          :label="r.label"
          :model-value="r.value"
          readonly
        />
      </CellGroup>
    </div>
    <Tabbar :qr-code="data?.qrCode" />
  </div>
</template>

<style module>

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.swiper {
  width: 100%;
  height: 300px;
  background-color: #eeeeee;
}
</style>
