<script setup lang="ts">
import { Button, CellGroup, Field, Form, showFailToast } from 'vant';
import { ref } from 'vue';
import axios from 'axios';
import MD5 from 'crypto-js/md5';
import { useRoute, useRouter } from 'vue-router';

const userId = ref('');
const password = ref('');

interface Response {
  email: string;
  enterprise_id: string;
  msg: string;
  name: string;
  outid: null;
  product_type: number;
  state: 0 | 1;
  user: string;
}

const router = useRouter();

const onSubmit = async () => {
  const resp = await axios.post<Response>('https://getuserinfoall-userserver-gggnycawlv.cn-hangzhou.fcapp.run', {
    'userId': userId.value,
    'pwdMD5': MD5(password.value).toString().toUpperCase(),
    'srcType': 'qingflow'
  });

  if (resp.data.state === 0) {
    showFailToast(resp.data.msg);
    return;
  }

  if (!resp.data.enterprise_id) {
    showFailToast(`${resp.data.user} enterprise_id 为空`);
    return;
  }

  localStorage.setItem('user', JSON.stringify({ ...resp.data }));

  await router.replace({
    name: 'admin-tryon'
  });
};

const route = useRoute();
const fromPath = route.query.from as string;
const query = JSON.parse(decodeURIComponent(route.query?.query as string || '{}'));

const onBack = async () => {
  await router.replace({
    path: fromPath || '/tryon',
    query: { ...query }
  });
};
</script>

<template>
  <div :class="$style.container">
    <Form @submit="onSubmit" :class="$style.form">
      <CellGroup inset>
        <Field
          v-model="userId"
          name="用户"
          label="用户"
          placeholder="用户"
          :rules="[{ required: true, message: '请填写用户' }]"
        />
        <Field
          v-model="password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
      </CellGroup>

      <div style="padding-top: 30px; width: 100%; display: flex; gap: 16px;">
        <Button block @click="onBack">
          返回
        </Button>
        <Button block type="primary" native-type="submit">
          提交
        </Button>
      </div>
    </Form>

  </div>
</template>

<style module>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  padding: 10px;
}

</style>
