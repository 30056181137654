<script setup lang="ts">
import { type ImageProps } from 'vant';

import { inject, onMounted, onUnmounted, ref, watch } from 'vue';
import { observerKey } from './index.ts';
import { getFileList, type QingflowFile } from '@/api/exhibition';
import { getFileType } from '@repo/utils';
import { Carousels } from '@/components/carousels';

interface DataQuery {
  enterpriseId: string;
  applyId: number;
  fieldName: string;
}

interface Props extends Omit<Partial<ImageProps>, 'src'> {
  query: DataQuery;
  height: string | number;
}

const props = defineProps<Props>();

const fileList = ref<(QingflowFile & { type: 'image' | 'video' | 'unknown' })[]>([]);

const imageContainer = ref<Element | null>(null);

const loadImage = async () => {
  try {
    const list = await getFileList(props.query);
    fileList.value = [...list.map(item => ({ ...item, type: getFileType(item.name) }))];
  } catch (error) {
    console.error(error);
    fileList.value = [];
  }
};

const observerProvider = inject(observerKey);
if (!observerProvider) {
  throw new Error('No IntersectionObserverProvider found!');
}

onMounted(() => {
  if (imageContainer.value) {
    observerProvider.observe(imageContainer.value, (entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        loadImage();
        observerProvider.unobserve(imageContainer.value!);
      }
    });
  }
});

onUnmounted(() => {
  if (imageContainer.value) {
    observerProvider.unobserve(imageContainer.value);
  }
});

watch(() => props.query, async () => {
  await loadImage();
});
</script>

<template>
  <div
    ref="imageContainer"
    :style="{
      height: props.height+'px',
      width: '100%'
    }"
  >
    <Carousels :height="height" :file-list="fileList" />
  </div>
</template>

<style scoped>
:deep(.van-swipe__track) {
  align-items: center;
}
</style>
