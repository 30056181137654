<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { getRecommendData, type RecommendDataItem } from '@/api/exhibition';
import RecommendCard from './recommend-card.vue';
import { LazyContainer } from '@/components';

const props = defineProps<{
  enterpriseId: string,
  dataId: string,
}>();

const recommendList = ref<RecommendDataItem[]>([]);

onMounted(async () => {
  try {
    const resp = await getRecommendData({
      enterpriseId: props.enterpriseId,
      dataId: props.dataId
    });

    recommendList.value = resp.items;
  } catch (e) {
    console.error(e);
  }
});

</script>

<template>
  <div :class="$style.container">
    <LazyContainer>
      <div :class="$style.cardList">
        <RecommendCard
          :enterpriseId="props.enterpriseId"
          :item="recommendItem"
          v-for="recommendItem of recommendList"
          :key="recommendItem.id"
        />
      </div>
    </LazyContainer>
  </div>
</template>

<style module>
.container {
  width: 100%;
  height: 100%;
}

.cardList {
  padding: 10px;
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(100px, auto) 1fr;
}

</style>
