<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { getFileList, getYarnData, type QingflowFile, type YarnData } from '@/api/exhibition';
import { Field, Image } from 'vant';
import { getFileType } from '@repo/utils';
import { ImagePreview } from '@/components';

const props = defineProps<{
  enterpriseId: string,
  dataId: string,
}>();

const yarnData = ref<YarnData>();

const fileList = ref<(QingflowFile & { type: 'video' | 'image' | 'unknown' })[]>([]);

onMounted(async () => {
  try {
    yarnData.value = await getYarnData({
      enterpriseId: props.enterpriseId,
      dataId: props.dataId
    });

    fileList.value = [];
    if (yarnData.value.coverImage) {
      const coverImages = await getFileList({ ...yarnData.value.coverImage, enterpriseId: props.enterpriseId });
      fileList.value = [...fileList.value, ...coverImages.map(item => ({ ...item, type: getFileType(item.name) }))];
    }

    if (yarnData.value.video) {
      const videos = await getFileList({ ...yarnData.value.video, enterpriseId: props.enterpriseId });
      fileList.value = [...fileList.value, ...videos.map(item => ({ ...item, type: getFileType(item.name) }))];
    }

    if (yarnData.value.image) {
      const images = await getFileList({ ...yarnData.value.image, enterpriseId: props.enterpriseId });
      fileList.value = [...fileList.value, ...images.map(item => ({ ...item, type: getFileType(item.name) }))];
    }
  } catch (e) {
    console.error(e);
  }
});

const showPreview = ref(false);
const startPosition = ref(0);
const images = computed(() => fileList.value.map(item => item.url));

const onClickPreview = (index: number) => {
  startPosition.value = index;
  showPreview.value = true;
};
</script>

<template>
  <div>
    <div>
      <template v-for="(file, index) of fileList" :key="file.url">
        <Image
          v-if="file.type==='image'"
          width="100%"
          :alt="file.name"
          :src="file.url"
          @click="onClickPreview(index)"
        />
        <video
          v-else-if="file.type==='video'"
          style="width: 100%;"
          preload="metadata"
          controls
          :autoplay="false"
          @click="onClickPreview(index)"
        >
          <source :src="file.url" type="video/mp4" />
        </video>
      </template>
      <ImagePreview
        v-model:show="showPreview"
        :images="images"
        :startPosition="startPosition"
        :close-on-click-image="false"
        :closeable="true"
      />
    </div>
    <Field
      v-for="[key,value] of Object.entries(yarnData?.property||{})"
      :key="key"
      :label="key"
      :model-value="value"
      readonly
    />
  </div>
</template>

<style scoped>

</style>
