import type { FabricStore, Project } from './typing.ts';
import { db } from './db.ts';


export async function saveRecentProject(project: Project & { date?: number }) {
  const found = await db.get('projects', project.id);
  if (found) {
    await db.put('projects', { ...project, date: found.date });
  } else {
    await db.put('projects', { ...project, date: Date.now() });
  }
}

export async function getRecentProjects(count: number = 10) {
  const data = await db.getAllFromIndex('projects', 'by-date', count, 'prev');
  return data;
}

export async function saveRecentFabric(fabric: FabricStore & { date?: number }) {
  const found = await db.get('fabrics', fabric.id);
  if (found) {
    await db.put('fabrics', { ...fabric, date: found.date });
  } else {
    await db.put('fabrics', { ...fabric, date: Date.now() });
  }
}

export async function getRecentFabrics(count: number = 10) {
  const data = await db.getAllFromIndex('fabrics', 'by-date', count, 'prev');
  return data;
}

