import { computed, onMounted, ref } from 'vue';
import {
  type FilterResult,
  getPlatformServicesResult,
  getPlatformServicesType,
  type PlatformServicesType
} from '@/api/cloud-resource';

let officialStyleInstance: ReturnType<typeof createOfficialStyleInstance> | null = null;

function createOfficialStyleInstance() {
  const type1 = ref('');
  const type2 = ref('');
  const type3 = ref('');

  const typeDatas = ref<PlatformServicesType[]>([]);
  const type1Options = computed(() => {
    const typeSet = new Set(typeDatas.value.map(item => item.type1));
    return [{ text: '大类', value: '' }, ...Array.from(typeSet).map(t => ({ text: t, value: t }))];
  });
  const type2Options = computed(() => {
    const type2List = typeDatas.value.filter(item => item.type1 === type1.value)
      .map(item => item.type2);
    const typeSet = new Set(type2List);
    return [{ text: '中类', value: '' }, ...Array.from(typeSet).map(t => ({ text: t, value: t }))];
  });
  const type3Options = computed(() => {
    const type3List = typeDatas.value.filter(item => item.type2 === type2.value)
      .map(item => item.type3);
    const typeSet = new Set(type3List);
    return [{ text: '小类', value: '' }, ...Array.from(typeSet).map(t => ({ text: t, value: t }))];
  });

  const results = ref<FilterResult[]>([]);

  onMounted(async () => {
    typeDatas.value = await getPlatformServicesType();

    loadFilter();
  });

  const onFilterChange = (type: 'type1' | 'type2' | 'type3') => {
    switch (type) {
      case 'type1':
        type2.value = '';
        type3.value = '';
        saveFilter();
        break;
      case 'type2':
        type3.value = '';
        saveFilter();
        break;
      case 'type3':
        saveFilter();
        break;
    }
  };

  const loadFilter = () => {
    const lastCloudPageFilter = JSON.parse(localStorage.getItem('lastOfficialStyleFilter') || '{}');

    type1.value = lastCloudPageFilter.type1 || '';
    type2.value = lastCloudPageFilter.type2 || '';
    type3.value = lastCloudPageFilter.type3 || '';
  };

  const saveFilter = () => {
    localStorage.setItem('lastOfficialStyleFilter', JSON.stringify({
      type1: type1.value,
      type2: type2.value,
      type3: type3.value
    }));
  };

  async function loadData(init = false, page = 1, keyword: string[] = []) {
    const resp = await getPlatformServicesResult({
      page,
      type1: type1.value,
      type2: type2.value,
      type3: type3.value,
      search: [...keyword]
    });
    results.value = [...(!init ? results.value : []), ...resp.data];
    return resp.data;
  }

  return { results, type1, type2, type3, onFilterChange, type1Options, type2Options, type3Options, loadData };
}

export const useOfficialStyle = () => {
  if (!officialStyleInstance) {
    officialStyleInstance = createOfficialStyleInstance();
  }
  return officialStyleInstance;
};
