<script setup lang="ts">
import { useFabricStore } from '@/store/fabric.ts';
import { computed } from 'vue';
import { Carousels } from '@/components';

const fabricStore = useFabricStore();
const showSwiper = computed(() => fabricStore?.fabricInfo?.carousels?.length);

const videos = computed(() => {
  const carousels = fabricStore?.fabricInfo?.carousels || [];
  return [
    ...carousels.filter(item => item.type === 'video')
      .map(item => ({
        url: item.url,
        thumbnail: `${item.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast`
      }))
  ];
});

const images = computed(() => {
  const carousels = fabricStore?.fabricInfo?.carousels || [];
  return [
    ...carousels.filter(item => item.type === 'picture').map(item => item.url)
  ];
});

const fileList = computed(() => {
  return [...videos.value.map(item => ({ url: item.url, type: 'video' })), ...images.value.map(url => ({
    url,
    type: 'image'
  }))] as { url: string, type: 'video' | 'image' }[];
});
</script>

<template>
  <div style="width: 100%;" v-if="showSwiper">
    <Carousels :height="400" :file-list="fileList" />
  </div>
</template>

<style scoped>
:deep(.van-swipe__track) {
  align-items: center;
}
</style>
