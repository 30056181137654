<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { Checkbox as VanCheckbox, Icon as VanIcon, Image as VanImage, Uploader as VanUploader } from 'vant';
import { ModelList, Toolbar } from './components';
import { $t } from '@/locales';
import { modelImageList, pantsImageList } from './models.ts';
import type { UploadFileInfo } from '@/views/tryon/typing.ts';
import { generateRandomUUID } from '@repo/utils';
import { getFabricEnterpriseId, uploadFileViaStsToken } from '@/api';

const route = useRoute();
const clothImage = ref(route.query.cloth as string);
const fabricId = ref(route.query.fabricId as string);
const originUrl = ref(route.query.originUrl as string);

const pantsImage = ref<UploadFileInfo>();


const modelImage = ref<UploadFileInfo>();
const showPants = ref(false);
const enterpriseId = ref('');

onMounted(async () => {
  const id = localStorage.getItem('lastTryonModelImage');
  if (id) {
    modelImage.value = modelImageList.value.find((item) => item.id === id);
  }

  enterpriseId.value = await getFabricEnterpriseId(originUrl.value);
});

const afterRead = async (item: any) => {
  try {
    const file: File = item.file;
    const key = generateRandomUUID();
    const date = new Date();

    const path = `/aitryon/${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}/${key}/` + file.name;
    const uploadedUrl: string = await uploadFileViaStsToken(path, file);
    if (uploadedUrl) {
      clothImage.value = uploadedUrl;
    }
  } catch (ex) {
    console.error(ex);
  }
};

</script>

<template>
  <div :class="$style.tryonPage">
    <div :class="$style.container">
      <h4 :class="$style.subtitle">{{ $t('tryon.model-pic') }}</h4>

      <ModelList :class="$style.modelList" :imageList="modelImageList" v-model="modelImage" />

      <h4 :class="$style.subtitle">{{ $t('tryon.upper') }}</h4>

      <div :class="$style.content">
        <van-image lazy-load width="100%" height="300" fit="contain" :src="clothImage" />
        <van-uploader :class="$style.modelUploader" :after-read="afterRead">
          <div :class="$style.iconContainer">
            <van-icon name="photograph" size="22"></van-icon>
          </div>
        </van-uploader>
      </div>

      <h4 :class="$style.subtitle">
        {{ $t('tryon.lower') }}
        <van-checkbox v-model="showPants" shape="square" style="margin-left: 5px;"></van-checkbox>
      </h4>

      <ModelList :class="$style.modelList" :imageList="pantsImageList" v-model="pantsImage" v-if="showPants" />

    </div>
    <Toolbar
      :class="$style.tabbar"
      :enterpriseId="enterpriseId"
      :clothImage="clothImage"
      :fabricId="fabricId"
      :originUrl="originUrl"
      :modelImage="modelImage"
      :pantsImage="pantsImage"
      :showPants="showPants"
    />
  </div>

</template>

<style module>

.tryonPage {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding-bottom: 40px;
}

.container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.content {
  width: 100%;
  overflow: hidden;
  padding: 0 10px;
  position: relative;
}

.modelList {
  padding: 0 10px 0;
  display: flex;
  align-items: center;
}

.modelUploader {
  position: absolute;
  border-radius: 5px;
  top: 10px;
  right: 10px;
}

.iconContainer {
  background: rgb(247, 248, 250);
  width: 28px;
  height: 28px;
  border-radius: 5px;
  padding-top: 3px;
  padding-left: 3px;
}

.subtitle {
  display: flex;
  margin-left: 10px;
  margin-top: 5px;
}

.tabbar {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
  background-color: #ffffff;
}

</style>
