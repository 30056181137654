<script setup lang="ts">
import { Carousels, Property, Recommend, Toolbar, Yarn } from './components';
import { Divider, Tab, Tabs } from 'vant';
import { ToolbarContainer } from '@/components';
import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';
import { type DataItem, getDataIdFromCode } from '@/api/exhibition';

const route = useRoute();

const url = route.query.originUrl as string;
const dataItem = ref<DataItem>();

onMounted(async () => {
  if (url) {
    try {
      dataItem.value = await getDataIdFromCode(url);
    } catch (e) {
      console.error(e);
    }
  }
});
</script>

<template>
  <div :class="$style.detailPage">
    <div :class="$style.container">
      <Carousels/>
      <Tabs scrollspy sticky>
        <Tab title="详情">
          <Property />
        </Tab>
        <Tab title="纱线" v-if="dataItem">
          <Divider>纱线</Divider>
          <Yarn :enterprise-id="dataItem.enterpriseId" :data-id="dataItem.id" />
        </Tab>
        <Tab title="推荐" v-if="dataItem">
          <Divider>推荐</Divider>
          <Recommend :enterprise-id="dataItem.enterpriseId" :data-id="dataItem.id" />
        </Tab>
      </Tabs>
    </div>
    <ToolbarContainer :dark="false">
      <Toolbar />
    </ToolbarContainer>
  </div>
</template>

<style module>
.detailPage {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}
</style>
