<script setup lang="ts">
import { Tabbar, TabbarItem } from 'vant';
import { computed } from 'vue';
import { $t } from '@/locales';
import { useRoute, useRouter } from 'vue-router';
import { processFabricProperty, validatorFabricUrl } from '@/utils/fabric-resource.ts';

const props = defineProps<{ qrCode?: string }>();

const route = useRoute();
const fromPath = route.query.from;
const query = JSON.parse(decodeURIComponent(route.query?.query as string || '{}'));

const returnRoute = computed(() => ({
  path: fromPath,
  query: { ...query }
}));

const valid = computed(() => props.qrCode && validatorFabricUrl(props.qrCode));

const router = useRouter();

const onClick = async () => {
  if (!props.qrCode) {
    return;
  }
  const params = await processFabricProperty(props.qrCode);
  await router.replace({
    name: 'fabric',
    params: { ...params },
    query: { originUrl: props.qrCode }
  });
};
</script>

<template>
  <Tabbar placeholder route safe-area-inset-bottom>
    <TabbarItem class="tabbar-item" replace :to="{...returnRoute}">
      {{ $t('common.return') }}
    </TabbarItem>
    <TabbarItem class="tabbar-item" v-if="valid" @click="onClick">
      应用
    </TabbarItem>
  </Tabbar>
</template>

<style scoped>
.tabbar-item {
  font-size: 16px;
}
</style>
