<script setup lang="ts">
import { SvgOutlineMinus, SvgOutlinePlus, SvgRotateLeft, SvgRotateRight } from '@repo/icons';
import { useStyle3d } from '@repo/style3d';
import { useRelationStore, useSelectionStore } from '@repo/stores';

const { setFabricRotation, setFabricCycle, selectMaterial } = useStyle3d();

const selectionStore = useSelectionStore();
const relationStore = useRelationStore();

const scale = defineModel<number>('scale', { default: 1 });
const angle = defineModel<number>('angle', { default: 0 });

const onScaleChange = async (scale: number) => {
  const lastSelected = selectionStore.selectedFabricId;

  const lastSelectedShape = relationStore.shapeList.find(item => item.id === lastSelected);
  const name = lastSelectedShape?.name;
  if (!name) {
    console.error('shape name is undefined!');
    return;
  }

  const sameNameShapeList = relationStore.shapeList.filter(item => item.name === name);

  for (const shape of sameNameShapeList) {
    await selectMaterial(shape.id);
    await setFabricCycle(scale);
  }
  await selectMaterial(lastSelected);
};

const onAngleChange = async () => {
  await setFabricRotation(angle.value);
};

const onClickMinus = async () => {
  let value = scale.value;
  value -= 0.1;
  if (value < 0.1) {
    value = 0.1;
  }
  await onScaleChange(value);
};

const onClickPlus = async () => {
  let value = scale.value;
  value += 0.1;
  if (value > 5.0) {
    value = 5.0;
  }
  await onScaleChange(value);
};

const onClickRotateLeft = async () => {
  angle.value -= 1;
  if (angle.value < 0) {
    angle.value = 360 - angle.value;
  }
  await onAngleChange();
};

const onClickRotateRight = async () => {
  angle.value += 1;
  if (angle.value > 360) {
    angle.value = angle.value - 360;
  }
  await onAngleChange();
};
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.column">
      <div :class="$style.label">放缩</div>
      <div style="display: flex;column-gap: 3px;">
        <SvgOutlineMinus :class="$style.icon" @click="onClickMinus"></SvgOutlineMinus>
        <div :class="$style.icon">{{ scale.toFixed(1) }}</div>
        <SvgOutlinePlus :class="$style.icon" @click="onClickPlus"></SvgOutlinePlus>
      </div>
    </div>
    <div :class="$style.column">
      <div :class="$style.label">角度</div>
      <div style="display: flex;column-gap: 3px;">
        <SvgRotateLeft :class="$style.icon" @click="onClickRotateLeft"></SvgRotateLeft>
        <div :class="$style.icon">{{ angle }}</div>
        <SvgRotateRight :class="$style.icon" @click="onClickRotateRight"></SvgRotateRight>
      </div>
    </div>
  </div>
</template>
<style module>
.container {
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 20px;
}

.column {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 10px;
}

.icon {
  border: 1px solid var(--van-gray-4);
  border-radius: 3px;
  width: 27px;
  height: 27px;
  cursor: pointer;
  color: var(--van-button-default-color);
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 27px;
}
</style>
