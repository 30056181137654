<script setup lang="ts">
import { $t } from '@repo/locales';
import { useRoute, useRouter } from 'vue-router';
import { Button, showNotify, showToast } from 'vant';
import { createTryonTask, fabricCheck, saveTryon } from '@/api';
import { useAddressBook } from '@/components';
import type { UploadFileInfo } from '@/views/tryon/typing.ts';
import { ref } from 'vue';

const router = useRouter();
const route = useRoute();
const { getAddressBook, toggleModal: toggleAddressModal } = useAddressBook(true);

const props = defineProps<{
  modelImage?: UploadFileInfo,
  pantsImage?: UploadFileInfo,
  fabricId: string,
  originUrl?:string,
  enterpriseId: string,
  clothImage: string,
  showPants: boolean,
}>();

const loading = ref(false);

const onClickTryon = async () => {
  if (!props.modelImage) {
    showNotify({ type: 'warning', message: $t('tryon.message.select-model') });
  } else {
    localStorage.setItem('lastTryonModelImage', props.modelImage.id);
    try {
      loading.value = true;

      const { limit } = await fabricCheck({
        fabricid: props.originUrl || props.fabricId,
        enterpriseid: props.enterpriseId,
        model: 'fabric-query'
      });

      const addressBook = getAddressBook();
      if (!addressBook && limit) {
        showToast($t('tryon.message.contact-first'));
        setTimeout(() => {
          toggleAddressModal(true);
        }, 1000);
        return;
      }


      const task = await createTryonTask({
        phone: addressBook?.phone,
        fabricid: props.originUrl || props.fabricId,
        enterpriseid: props.enterpriseId,
        model: 'aitryon',
        input: {
          top_garment_url: props.clothImage,
          bottom_garment_url: props.pantsImage?.url,
          person_image_url: props.modelImage?.url
        }
      });

      if (task.status === 'SUCCEED') {
        await saveTryon({
          id: 0,
          clothUrl: props.clothImage,
          modelUrl: props.modelImage.url,
          task_status: task.output.task_status,
          task_id: task.output.task_id,
          enterpriseid: props.enterpriseId,
          request_id: task.request_id,
          pantsUrl: props.pantsImage?.url
        });

        await router.replace({
          path: '/mytryon',
          state: {
            params: {
              cloth: props.modelImage?.url,
              pants: props.showPants && props.pantsImage ? props.pantsImage.url : null
            }
          }
        });
      } else {
        showNotify({ type: 'danger', message: task.message });
      }
    } catch (e: any) {
      showNotify({ type: 'danger', message: e.message });
    } finally {
      loading.value = false;
    }
  }
};

const onClickMyTryon = async () => {
  await router.replace(`/mytryon`);
};

const onClickAISetting = async () => {
  await router.replace({
    name: 'admin-tryon',
    query: {
      from: route.path,
      query: encodeURIComponent(JSON.stringify(route.query))
    }
  });
};

</script>

<template>
  <div :class="$style.tabbarContainer">
    <Button :class="$style.button" :loading="loading" :loading-text="`${$t('tryon.start')}...`" @click="onClickTryon">
      {{ $t('tryon.start') }}
    </Button>
    <Button :class="$style.button" @click="onClickMyTryon">
      {{ $t('tryon.history') }}
    </Button>
    <Button :class="$style.button" @click="onClickAISetting">
      AI试衣设置
    </Button>
  </div>

</template>

<style module>
.tabbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 5px;
  column-gap: 15px;
  width: 100%;
  padding: 5px 15px;
  font-size: 16px;
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
