<script setup lang="ts">
import { inject, onMounted, onUnmounted, ref } from 'vue';
import { observerKey } from './index.ts';
import { Image, type ImageProps, Loading } from 'vant';
import { getFileList } from '@/api/exhibition';

interface DataQuery {
  enterpriseId: string;
  applyId: number;
  fieldName: string;
}

interface Props extends Omit<Partial<ImageProps>, 'src'> {
  query: DataQuery;
}

const props = defineProps<Props>();

const imageUrl = ref('');

const imageContainer = ref<Element | null>(null);

const loadImage = async () => {
  try {
    const fileList = await getFileList(props.query);
    imageUrl.value = fileList?.[0]?.url || '';
  } catch (error) {
    console.error(error);
    imageUrl.value = '';
  }
};

const observerProvider = inject(observerKey);
if (!observerProvider) {
  throw new Error('No IntersectionObserverProvider found!');
}

onMounted(() => {
  if (imageContainer.value) {
    observerProvider.observe(imageContainer.value, (entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        loadImage();
        observerProvider.unobserve(imageContainer.value!);
      }
    });
  }
});

onUnmounted(() => {
  if (imageContainer.value) {
    observerProvider.unobserve(imageContainer.value);
  }
});
</script>

<template>
  <div ref="imageContainer" :style="{ width:props.width, height:props.height }">
    <Image v-bind="props" :src="imageUrl" :show-loading="true" :show-error="true">
      <template v-slot:loading>
        <Loading type="spinner" size="20" />
      </template>
    </Image>
  </div>
</template>

<style scoped>
</style>
