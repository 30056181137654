<script setup lang="ts">
import { ImageCard } from './components';
import { onMounted, ref } from 'vue';
import { type TryOn, getTryons, saveTryon, queryTryonTask } from '@/api';
import { useTimer } from '@/use';
import type { TryOnItem } from './typing.ts';

const tryons = ref<TryOnItem[]>([]);

const { timerCallback } = useTimer(5000);
timerCallback.value = () => {
  refreshTaskStatus();
};

onMounted(async () => {
  //query
  const params = history.state.params;
  if (params) {
    console.log('MyTryon[mounted]:');
    console.log('cloth', params.cloth);
    console.log('pants', params.pants);
  }

  await queryTryons();
});


const fabricNameMapping = (item: TryOn) => {
  const name = '' + item.id;
  return { ...item, name };
};

const isExpired = (item: TryOn) => {
  if (!item.expires) {
    return false;
  }
  const expireTime = new Date(item.expires);
  return Date.now() - expireTime.getTime() > 0;
};

const queryTryons = async () => {
  const items: TryOn[] = await getTryons(9999);

  tryons.value = items.filter(item => !isExpired(item)).map(fabricNameMapping);
};

function extractExpiryTime(url: string) {
  const urlObj = new URL(url);

  const expires = urlObj.searchParams.get('Expires');

  if (expires) {
    const expiryDate = new Date(Number(expires) * 1000);
    return expiryDate.getTime();
  } else {
    return undefined;
  }
}

const refreshTaskStatus = async () => {
  for (const tryon of tryons.value) {
    if (!!tryon.task_id && (tryon.task_status === 'PENDING' || tryon.task_status === 'RUNNING')) {
      try {
        const newTaskStatus = await queryTryonTask(tryon.task_id, tryon?.enterpriseid || '');

        if (newTaskStatus.output.task_id === tryon.task_id) {
          tryon.imageUrl = newTaskStatus.output.image_url;
          tryon.task_status = newTaskStatus.output.task_status;
          tryon.expires = newTaskStatus.output.image_url ? extractExpiryTime(newTaskStatus.output.image_url) : undefined;

          await saveTryon(tryon);
        }
      } catch (ex: any) {
        console.error('update tryon task failed', ex);
      }
    }
  }
};
</script>

<template>
  <div :class="$style.mytryonPage">
    <div :class="$style.content">
      <div :class="$style.tryonList">
        <ImageCard
          :class="$style.imageItem"
          v-bind="item" v-for="item in tryons"
          :key="item.id"
        />
      </div>
    </div>
  </div>
</template>

<style module>
.mytryonPage {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tryonList {
  flex: 1;
  width: 100%;
  padding: 0 10px;
  overflow-y: auto;
}

.imageItem {
  margin-top: 10px;
}
</style>
