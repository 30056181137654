<script setup lang="ts">
import type { ExhibitionData } from '@/api/exhibition';
import { TextEllipsis } from 'vant';
import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';
import { LazyImage } from '@/components';

const props = defineProps<{
  data: ExhibitionData
}>();

const rows = computed(() => props.data.displayData.render
  .filter(item => !item.is_other)
  .map(item => ({ key: item.key, label: item.name, value: props.data.displayData.metadata[item.key] })));

const name = computed(() => rows.value.find(item => item.key === 'name')?.value || '');

const images = computed(() => ({ enterpriseId: props.data.enterpriseId, ...props.data.displayData.files.image }));

const router = useRouter();
const route = useRoute();

const onClick = async () => {
  await router.replace({
    name: 'data-detail',
    params: {
      dataid: props.data.id
    },
    query: {
      from: route.path,
      query: encodeURIComponent(JSON.stringify(route.query))
    }
  });
};
</script>

<template>
  <div :class="$style.exhibitionCard" @click="onClick">
    <LazyImage
      width="100%"
      fit="contain"
      :query="images"
    />
    <div :class="$style.desc">
      <div :class="$style.row">
        <TextEllipsis :content="name" />
      </div>
    </div>
  </div>
</template>

<style module>
.exhibitionCard {
  padding: 3px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.desc {
  height: 100%;
  width: 100%;
  padding: 10px 5px;
}

.desc .row {
  width: 100%;
}
</style>
