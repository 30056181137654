<script setup lang="ts">
import { provide, onMounted, onUnmounted } from 'vue';
import { observerKey, IntersectionObserverProvider } from './index.ts';

const observerProvider = new IntersectionObserverProvider({ threshold: 0.1 });

onMounted(() => {
  console.log('IntersectionObserver provider mounted');
});

onUnmounted(() => {
  observerProvider.disconnect();
});

provide(observerKey, observerProvider);
</script>

<template>
  <slot />
</template>

<style scoped>

</style>
