import type { RouteRecordRaw } from 'vue-router';
import { FabricPage } from '@/views/fabric';
import { FavoritePage } from '@/views/favorite';
import { TryOnPage } from '@/views/tryon';
import { TailorPage } from '@/views/tailor';
import { MyTryOnPage } from '@/views/mytryon';
import { BasicLayout, HomeLayout } from '@/layouts';
import { DetailPage } from '@/views/detail';
import { CloudPage } from '@/views/cloud';
import { ExhibitionListPage } from '@/views/exhibition-list';
import { DataDetailPage } from '@/views/data-detail';
import { AdminLoginPage } from '@/views/admin/login';
import { AdminTryonPage } from '@/views/admin/tryon';

export const routes: RouteRecordRaw[] = [
  {
    component: HomeLayout,
    path: '/',
    redirect: '/fabric/default',
    children: [
      {
        name: 'project',
        path: '/:id',
        alias: '/project/:id',
        component: FabricPage
      },
      {
        name: 'fabric',
        path: '/fabric/:type?/:id',
        component: FabricPage
      },
      {
        name: 'favorite',
        path: '/favorite',
        component: FavoritePage
      },
      {
        name: 'tailor',
        path: '/tailor',
        component: TailorPage
      },
      {
        name: 'tryon',
        path: '/tryon',
        component: TryOnPage
      },
      {
        name: 'mytryon',
        path: '/mytryon',
        component: MyTryOnPage
      },
      {
        name: 'detail',
        path: '/detail/:type?/:id',
        component: DetailPage
      }
    ]
  },
  {
    path: '/cloud',
    component: BasicLayout,
    children: [
      {
        name: 'cloud',
        path: '',
        component: CloudPage
      }
    ]
  },
  {
    path: '/exhibition/:enterpriseId',
    component: BasicLayout,
    children: [
      {
        name: 'exhibition-list',
        path: '',
        component: ExhibitionListPage
      },
    ]
  },
  {
    path: '/data/:dataid',
    component: BasicLayout,
    children: [
      {
        name: 'data-detail',
        path: '',
        component: DataDetailPage
      },
    ]
  },
  {
    path: '/admin',
    component: BasicLayout,
    children: [
      {
        name: 'admin-login',
        path: 'login',
        component: AdminLoginPage
      },
      {
        name: 'admin-tryon',
        path: 'tryon',
        component: AdminTryonPage
      }
    ]
  },
];
