<script setup lang="ts">
import { getFileTypeFromUrl } from '@repo/utils';
import { ImagePreview as VantImagePreview } from 'vant';

const showPreview = defineModel<boolean>('show');

const props = withDefaults(
  defineProps<{
    images: string[],
    startPosition: number
  }>(),
  { startPosition: 0 }
);
</script>

<template>
  <VantImagePreview
    v-model:show="showPreview"
    :images="props.images"
    :startPosition="props.startPosition"
    :close-on-click-image="false"
    :closeable="true"
  >
    <template #image="{ src,style,onLoad }">
      <template v-if="getFileTypeFromUrl(src)==='video'">
        <video style="width: 100%;" controls>
          <source :src="src" />
        </video>
      </template>
      <template v-else>
        <img :src="src" :style="[{ width: '100%' }, style]" @onLoad="onLoad" />
      </template>
    </template>
  </VantImagePreview>
</template>

<style scoped>

</style>
