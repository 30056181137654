import type { InjectionKey } from 'vue';

export type ObserverCallback = (entry: IntersectionObserverEntry) => void;

export class IntersectionObserverProvider {
  private observer: IntersectionObserver;
  private callbacks: Map<Element, ObserverCallback>;

  constructor(options: IntersectionObserverInit) {
    this.callbacks = new Map();
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const callback = this.callbacks.get(entry.target);
        if (callback) callback(entry);
      });
    }, options);
  }

  observe(element: Element, callback: ObserverCallback) {
    if (this.callbacks.has(element)) return;
    this.callbacks.set(element, callback);
    this.observer.observe(element);
  }

  unobserve(element: Element) {
    if (!this.callbacks.has(element)) return;
    this.callbacks.delete(element);
    this.observer.unobserve(element);
  }

  disconnect() {
    this.callbacks.clear();
    this.observer.disconnect();
  }
}

export const observerKey = Symbol('IntersectionObserverProvider') as InjectionKey<IntersectionObserverProvider>;

export { default as LazyImage } from './lazy-image.vue';

export { default as LazyCarousels } from './lazy-carousels.vue';

export { default as LazyContainer } from './lazy-container.vue';
