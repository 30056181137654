<script setup lang="ts">
import { Button, Checkbox, Row, Space, TextEllipsis } from 'vant';
import type { FavoriteItem } from '../typing.ts';
import { FavoriteEdit, Image } from '@/components';
import { useRouter } from 'vue-router';
import { $t } from '@/locales';

const props = defineProps<{
  item: FavoriteItem
}>();

const emit = defineEmits<{
  (e: 'selected', val: boolean): void,
  (e: 'change', payload: { id: string, memo: string, num: number }): void
}>();

const onClick = () => {
  emit('selected', !props.item.selected);
};

const router = useRouter();
const onClickDetail = (fav: FavoriteItem) => {
  if (fav.resourceType === 'style3d') {
    router.replace({
      name: 'detail',
      params: {
        type: 'style3d',
        id: fav.fabricId
      },
      query: {
        originUrl: fav.originUrl
      }
    });
  } else {
    router.replace(
      {
        name: 'detail',
        params: {
          id: fav.fabricId
        },
        query: {
          originUrl: fav.originUrl
        }
      }
    );
  }
};
</script>

<template>
  <div :class="$style.fabricCard" @click="onClick">
    <Checkbox :class="$style.check" :modelValue="true" v-if="props.item.selected" />
    <div :class="$style.imageContainer" @click="onClickDetail(props.item)">
      <Image :src="props.item.thumb" :width="95" :height="95" fit="scale-down" />
    </div>
    <div :class="$style.desc">
      <div :class="$style.row">
        <div>{{ $t('common.name') }}</div>
        <div>
          <TextEllipsis :content="props.item.info?.name||''" />
        </div>
      </div>
      <div :class="$style.row">
        <div>{{ $t('common.id') }}</div>
        <div>{{ props.item.id }}</div>
      </div>
      <div :class="$style.row">
        <div>{{ $t('common.memo') }}</div>
        <div>
          <TextEllipsis :content="props.item.memo" />
        </div>
      </div>
      <div :class="$style.row">
        <div>{{ $t('common.quantity') }}</div>
        <Row justify="space-between" style="width: 100%">
          <div>{{ props.item.num }}</div>
          <Space>
            <Button
              size="small"
              @click="()=>onClickDetail({...props.item})"
            >
              {{ $t('common.details') }}
            </Button>
            <FavoriteEdit
              :fabric="props.item"
              :memo="props.item.memo"
              :num="props.item.num"
              :show-form="true"
              @click.stop=""
              @success="(val)=>emit('change',val)"
            >
              <Button size="small">{{ $t('common.edit') }}</Button>
            </FavoriteEdit>
          </Space>
        </Row>
      </div>
    </div>
  </div>
</template>

<style module>
.fabricCard {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 130px;
  border: solid 1px #d0d0d0;
  border-radius: 7px;
  align-items: center;
  justify-content: space-around;
}

.check {
  position: absolute;
  right: 10px;
  top: 10px;
}

.imageContainer {
  flex: 0;
  padding: 5px;
  display: flex;
  align-items: center;
}

.desc {
  flex: 1;
  padding: 5px;
}

.desc .row {
  display: grid;
  grid-template-columns: 1fr 3fr;
  font-size: 16px;
  line-height: 1.6;
}
</style>
