import { defineStore } from '@repo/stores';
import { ref, watch } from 'vue';
import {getRecentProjects, type Project, saveRecentProject} from '@/api';

export const useRecentProjectListStore = defineStore('recent-project-list', () => {
  const recentUpdate = ref<number>();
  const recentProjects = ref<Project[]>([]);

  const addRecentProject=async (project:Project)=>{
    await saveRecentProject(project);
    recentUpdate.value = Date.now();
  }

  watch(() => recentUpdate.value, async () => {
    recentProjects.value = await getRecentProjects(30);
  }, {
    immediate: true
  });

  return { recentUpdate, recentProjects, addRecentProject };
});
