import {
  createRouter,
  createWebHistory
} from 'vue-router';

import { routes } from './routes';
import { isIOS } from '@repo/utils';
import { useWx } from '@repo/wx';

const router = createRouter({
  history: createWebHistory(),
  routes
});

const { wxConfig } = useWx();

router.afterEach(async () => {
  if (!isIOS()) {
    wxConfig(location.href).then(() => {
      console.log('wxConfig success');
    });
  }
});

router.beforeEach((_to, from) => {
  const fName = (from.name as string) || '';
  if (['fabric', 'detail'].includes(fName)) {
    const { params, query } = from;

    localStorage.setItem('lastRoute', JSON.stringify({ name: fName, params, query }));
  }
});

router.beforeEach((to, from) => {
  if (['admin-tryon'].includes(to.name as string)) {
    const savedUser = localStorage.getItem('user');
    if (!savedUser) {
      return router.replace({
        name: 'admin-login',
        query: {
          from: from.path,
          query: encodeURIComponent(JSON.stringify(from.query))
        }
      });
    }
  }
});

export { router };
