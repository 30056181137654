<script setup lang="ts">
import { $t } from '@repo/locales';
import { Button, Loading, showToast } from 'vant';
import { QrScan } from '@/components';
import { getAppConfig, getFabricShareInfo } from '@/utils';
import { useParamStore } from '@/store/param.ts';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { joinUrl } from '@repo/utils';
import { type FavoriteStore, getStyle3dFabricApi, saveFavorite } from '@/api';
import { nextTick, ref } from 'vue';
import { cloudKnitFabricReg, parseStyle3dUShortUrl, style3dReg, style3dUShortReg } from '@/utils/fabric-resource.ts';

// cloudknit款式
const cloudKnitReg = /^(?:https?:\/\/)?(?:[\w-]+\.)?cloudknit\.cn(?:\/project)?\/(\w+)$/;
const oldCloudKnitReg = /^(?:https?:\/\/)?([\w-]+)\.?cloudknit\.cn\/\?pathId=([a-zA-Z0-9]+)/;

// 凌迪廓形短链接
const style3dRShortReg = /(?:https?:\/\/)?(?:[\w-]+\.)?style3d\.com\/r\/(\w+)/;

const validator = (text: string) => {
  return oldCloudKnitReg.test(text) ||
    cloudKnitReg.test(text) ||
    cloudKnitFabricReg.test(text) ||
    style3dReg.test(text) ||
    style3dUShortReg.test(text) ||
    style3dRShortReg.test(text);
};

const { URL_PARSE_URL } = getAppConfig();
const { refreshLoadResourceByRoute } = useParamStore();

const router = useRouter();
const route = useRoute();
const loading = ref(false);

let originUrl: string = '';

const onScanSuccess = async (val: string) => {
  originUrl = val;
  try {
    loading.value = true;
    switch (route.name) {
      case 'favorite':
        await handleFavoriteCase(val);
        break;
      default:
        await handleNormalCase(val);
        break;
    }
  } catch (e: any) {
    loading.value = false;
    showToast(e.message);
  }
};

const handleNormalCase = async (val: string) => {
  if (cloudKnitReg.test(val) || oldCloudKnitReg.test(val)) {
    const match = val.match(cloudKnitReg) || val.match(oldCloudKnitReg);
    if (match && match[1]) {
      await handleCloudKnitResource(match[1]);
    }
  } else if (cloudKnitFabricReg.test(val)) {
    const match = val.match(cloudKnitFabricReg);
    if (match && match[1]) {
      await handleCloudKnitFabricResource(match[1]);
    }
  } else if (style3dReg.test(val)) {
    await handleStyle3dFabricResource(val);
  } else if (style3dUShortReg.test(val)) {
    const match = val.match(style3dUShortReg);
    if (match && match[1]) {
      const url = await parseStyle3dUShortUrl(match[1]);
      await handleStyle3dFabricResource(url);
    }
  } else if (style3dRShortReg.test(val)) {
    await handleStyle3dResource(val);
  }
};

const handleFavoriteCase = async (val: string) => {
  if (cloudKnitFabricReg.test(val)) {
    const match = val.match(cloudKnitFabricReg);
    if (match && match[1]) {
      const info = await getFabricShareInfo(match[1], originUrl);
      await favoriteFabric(info);
    }
  } else if (style3dReg.test(val)) {
    await favoriteStyle3dFabric(val);
  } else if (style3dUShortReg.test(val)) {
    const match = val.match(style3dUShortReg);
    if (match && match[1]) {
      const url = await parseStyle3dUShortUrl(match[1]);
      await favoriteStyle3dFabric(url);
    }
  } else {
    showToast('请到3D或详情页扫码');
  }
};

const handleCloudKnitResource = async (id: string) => {
  await router.replace({
    name: 'fabric',
    params: { ...route.params },
    query: {
      ...route.query,
      scoId: id
    }
  });
  await refreshLoadResourceByRoute();
};

const handleCloudKnitFabricResource = async (id: string) => {
  await router.replace({ params: { id, type: 'cloudknit' }, query: { ...route.query, originUrl } });
  await refreshLoadResourceByRoute();
};

/*
* https://www.style3d.com/cd/fabric/4930435?is_print_tag=1&func=resfabric
* https://www.style3d.com/fabric/4753097?is_print_tag=1&func=resfabric
* 解析不到initial_data，需要通过url解析id
*/
const handleStyle3dFabricResource = async (url: string) => {
  const regex = /fabric\/(\d+)(?:\?|\b)/;
  let id = url.match(regex)?.[1];
  try {
    const response = await axios.get<string>(joinUrl(URL_PARSE_URL, `/style3d/initial_data?url=${encodeURIComponent(url)}`));
    id = (response.data as any).m3dStore.detailData.extra?.renderData?.id;
  } catch (e: any) {
    console.log(e.message);
  }
  if (!id) {
    throw new Error('解析错误');
  }
  await router.replace({ params: { id, type: 'style3d' }, query: { ...route.query, originUrl } });
  await refreshLoadResourceByRoute();
};

const handleStyle3dResource = async (val: string) => {
  try {
    const match = val.match(style3dRShortReg);
    const scoId = match && match[1];

    const response = await axios.get<string>(joinUrl(URL_PARSE_URL, `/style3d/initial_data?url=${encodeURIComponent(val)}`));
    const sco = (response.data as any).m3dStore.detailData.data.modelPath.clothSrc.src;
    if (!sco) {
      throw new Error('解析错误');
    }
    await router.replace({
      name: 'fabric',
      params: { ...route.params },
      query: { sco: encodeURIComponent(sco), scoId }
    });
    await refreshLoadResourceByRoute();
  } catch (error) {
    console.error('Error fetching the data:', error);
  }
};

const favoriteFabric = async (fabric: Omit<FavoriteStore, 'date' | 'terms' | 'resourceType'>) => {
  const data = { ...fabric, fabricId: fabric.id, resourceType: 'cloudknit' as const, memo: '', num: 1 };
  await saveFavorite(data);
  router.go(0);

  await nextTick(() => {
    showToast($t('message.favorite-success'));
  });
};

const favoriteStyle3dFabric = async (url: string) => {
  const response = await axios.get<string>(joinUrl(URL_PARSE_URL, `/style3d/initial_data?url=${encodeURIComponent(url)}`));
  const id = (response.data as any).m3dStore.detailData.extra?.renderData?.id;
  if (!id) {
    throw new Error('解析错误');
  }

  const style3dFabric = await getStyle3dFabricApi(id);

  const keyMap = style3dFabric.create_info.fields.map(item => ({ name: item.name, key: item.key }));
  const fabricProperty = keyMap
    .map((item) => ({ name: item.name, key: item.key, value: style3dFabric.data[item.key] }))
    .filter(item => item.value);

  await saveFavorite({
    id: `style3d-${id}`,
    originUrl,
    fabricId: `${id}`,
    thumb: style3dFabric.data.thumb_path,
    resourceType: 'style3d',
    properties: fabricProperty,
    info: {
      name: style3dFabric.data.name
    }
  });

  router.go(0);
  await nextTick(() => {
    showToast($t('message.favorite-success'));
  });
};
</script>

<template>
  <QrScan :validator="validator" :callback="onScanSuccess">
    <template #scan-icon="slotProps">
      <Button type="primary">
        {{ $t('tabbar.scan') }}
        <template #loading>
          <Loading v-if="slotProps.loading" size="18" />
        </template>
      </Button>
    </template>
  </QrScan>

</template>

<style scoped>

</style>
