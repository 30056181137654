<script setup lang="ts">
import { LazyCarousels, LazyContainer } from '@/components/lazy-image';
import { useRoute, useRouter } from 'vue-router';
import { Tabbar } from './components/tabbar';
import { ExhibitionDetail } from './components/exhibition-detail';
import { type Exhibition, getEnterpriseExhibitionList } from '@/api/exhibition';
import { computed, onMounted, ref } from 'vue';
import { ConfigProvider, Sidebar, SidebarItem } from 'vant';

const route = useRoute();
const enterpriseId = route.params.enterpriseId as string;
const activeTab = route.query.active as string;

const exhibitionList = ref<Exhibition[]>([]);

onMounted(async () => {
  exhibitionList.value = await getEnterpriseExhibitionList({ enterpriseId });
});

const themeVars = {
  sidebarWidth: '100px'
};

const active = ref(0);
active.value = parseInt(activeTab) || 0;

const exhibitionData = computed(() => exhibitionList.value?.[active.value]);

const router= useRouter();
const onActiveChange=(index:number)=>{
  router.replace({
    query:{
      active: index
    }
  })
}
</script>

<template>
  <div :class="$style.container">
    <ConfigProvider :theme-vars="themeVars">
      <Sidebar v-model="active" @change="onActiveChange" style="max-height: 100%;overflow: auto;">
        <SidebarItem
          :title="exhibition.name"
          v-for="exhibition of exhibitionList"
          :key="exhibition.id"
        />
      </Sidebar>
    </ConfigProvider>
    <div :class="$style.mainContent">
      <LazyContainer>
        <div :class="$style.swiper">
          <LazyCarousels
            v-if="exhibitionData"
            :query="{...exhibitionData.coverImageData,enterpriseId :exhibitionData.enterpriseId}"
            height="230"
          />
        </div>
        <ExhibitionDetail
          v-if="exhibitionData"
          :enterprise-id="exhibitionData.enterpriseId"
          :external-id="exhibitionData.externalId"
        />
      </LazyContainer>
    </div>
    <Tabbar />
  </div>
</template>

<style module>
.container {
  display: flex;
  flex-direction: row;
  row-gap: 10px;
  padding: 10px 10px 50px;
  overflow: auto;
  height: 100vh;
}

.swiper {
  width: 100%;
  background-color: #eeeeee;
}

.mainContent {
  width: calc(100% - 100px);
  height: 100%;
  overflow: auto;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
</style>
