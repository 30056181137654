<script setup lang="ts">
import { ColorPicker } from '@/components/color-picker';
import { SvgColorPalette } from '@repo/icons';

import { useFabricStore } from '@/store/fabric.ts';
import { useRelationStore, useSelectionStore } from '@repo/stores';
import { useStyle3d } from '@repo/style3d';

const defaultPalette = [
  {
    colorNo: '1',
    color: `rgb(169, 216, 246)`
  },
  {
    colorNo: '2',
    color: `rgb(206, 226, 224)`
  },
  {
    colorNo: '3',
    color: `rgb(249, 240, 235)`
  },
  {
    colorNo: '4',
    color: `rgb(214, 159, 152)`
  },
  {
    colorNo: '5',
    color: `rgb(183, 171, 185)`
  }
];

const fabricStore = useFabricStore();
const selectionStore = useSelectionStore();
const relationStore = useRelationStore();

const { changeFabricColor, selectMaterial } = useStyle3d();

const onClickColor = async (color: string) => {
  const { selectedFabricId } = selectionStore;
  const found = relationStore.shapeList.find(item => item.id === selectedFabricId);
  if (selectedFabricId === '' || found) {
    const materialIndex = found?.materialIndex;
    const filteredList = relationStore.shapeList.filter(item => {
      if (materialIndex === undefined) {
        return item.materialIndex === 0 || item.materialIndex === undefined;
      }
      return item.materialIndex === materialIndex;
    });

    for (const item of filteredList) {
      await selectMaterial(item.id);
      await changeFabricColor(color);
    }

    selectionStore.setSelectedFabric('')
  } else {
    // 可能是钮扣之类的
    await selectMaterial(selectedFabricId);
    await changeFabricColor(color);
  }
};

</script>

<template>
  <div :class="$style.container">
    <div
      v-for="p of fabricStore.palette||defaultPalette"
      :key="p.colorNo"
      :style="{ backgroundColor: p.color }"
      style="width: 30px;height:30px;"
      @click="()=>onClickColor(p.color)"
    />
    <ColorPicker style="width: 30px;height:30px;" @confirm="onClickColor">
      <SvgColorPalette style="width: 100%; height: 100%;" />
    </ColorPicker>
  </div>
</template>

<style module>
.container {
  padding: 20px 10px 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
</style>
