<script setup lang="ts">
import type { RecommendDataItem } from '@/api/exhibition';
import { computed } from 'vue';
import { LazyImage } from '@/components';
import { TextEllipsis } from 'vant';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps<{ item: RecommendDataItem, enterpriseId: string }>();

const image = computed(() => ({ enterpriseId: props.enterpriseId, ...props.item.displayData.files.image }));

const rows = computed(() => props.item.displayData.render
  .filter(item => !item.is_other)
  .map(item => ({ key: item.key, label: item.name, value: props.item.displayData.metadata[item.key] })));

const router = useRouter();
const route = useRoute();

const onClick = async () => {
  await router.replace({
    name: 'data-detail',
    params: {
      dataid: props.item.id
    },
    query: {
      from: route.path,
      query: encodeURIComponent(JSON.stringify(route.query)),
    }
  });
};
</script>

<template>
  <div :class="$style.card" @click="onClick">
    <LazyImage
      :query="image"
      width="100%"
      height="130"
      fit="contain"
    />
    <div :class="$style.desc">
      <div :class="$style.row" v-for="r of rows" :key="r.key">
        <TextEllipsis :content="r.value" />
      </div>
    </div>
  </div>
</template>

<style module>
.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: solid 1px #eeeeee;
}

.desc {
  height: 100%;
  width: 100%;
  padding: 10px 5px;
}

.desc .row {
  width: 100%;
}
</style>
