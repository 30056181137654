import { defineStore } from '@repo/stores';
import type { FabricDoc, MetadataProperty, MetaInfo, Palette } from '@repo/style3d';
import { computed, ref, toRaw, watch } from 'vue';
import { setWindowTitle } from '@repo/utils';
import { getAppConfig } from '@/utils';
import { useRecentFabricListStore } from '@/store/recent-fabric-list.ts';

const { DEFAULT_COMPANY_NAME } = getAppConfig();

export interface FabricInfo {
  id: string;
  originUrl?: string;
  fabricId: string;
  info: MetaInfo;
  thumb: string;
  enterpriseId: string;
  enterpriseName: string;
  resourceType?: 'cloudknit' | 'style3d';
  properties: Record<string, string>[];
  styleProperties?: Record<string, string>[];
  carousels?: (FabricDoc & { url: string })[];
}

export interface ColorPalette extends Omit<Palette, 'color'> {
  color: string;
}

export const useFabricStore = defineStore('fabric', () => {
  const fabricInfo = ref<FabricInfo>();
  const recentFabricStore = useRecentFabricListStore();

  const fabricProperty = computed(() => {
    return fabricInfo.value?.properties || [];
  });

  const styleProperty = computed(() => {
    return fabricInfo.value?.styleProperties || [];
  });

  const palette = computed<ColorPalette[] | undefined>(() => {
    return fabricInfo.value?.info?.palette?.map(item => ({
      ...item,
      color: `rgb(${item.color.R}, ${item.color.G}, ${item.color.B})`
    }));
  });

  const setFabricInfo = async (val: FabricInfo) => {
    fabricInfo.value = val;

    const {
      id,
      fabricId,
      originUrl,
      thumb,
      enterpriseId,
      enterpriseName,
      info,
      resourceType = 'cloudknit',
      properties
    } = val;

    await recentFabricStore.addRecentFabric({
      id,
      fabricId,
      originUrl,
      resourceType,
      info: toRaw(info),
      thumb: thumb || '',
      enterpriseId,
      enterpriseName,
      properties: toRaw(properties || [])
    });
  };

  const setFabricProperties = (val: MetadataProperty['data']) => {
    if (fabricInfo.value) {
      fabricInfo.value.properties = [...val.map(toRaw)];
    }
  };

  const setFabricThumb = (url: string) => {
    if (fabricInfo.value) {
      fabricInfo.value.thumb = url;
    }
  };

  watch(() => fabricInfo.value?.enterpriseName, (val) => {
    setWindowTitle(val || DEFAULT_COMPANY_NAME);
  });


  return { fabricInfo, fabricProperty, styleProperty, setFabricInfo, setFabricProperties, setFabricThumb, palette };
});
