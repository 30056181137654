<script setup lang="ts">
import { ToolButton } from '@/components';
import { SvgExhibition } from '@repo/icons';
import { useRouter } from 'vue-router';
import { showNotify } from 'vant';
import { useFabricStore } from '@/store/fabric.ts';

const router = useRouter();

const fabricStore = useFabricStore();

const onClickExhibition = async () => {

  if (!fabricStore?.fabricInfo?.enterpriseId) {
    showNotify({ type: 'danger', message: '获取企业id失败' });
    return;
  }
  await router.replace({
    name: 'exhibition-list',
    params: {
      enterpriseId: fabricStore.fabricInfo.enterpriseId
    }
  });
};
</script>

<template>
  <ToolButton @click="onClickExhibition">
    <SvgExhibition style="width: 20px;height:20px;" />
  </ToolButton>
</template>

<style module>

</style>
