<script setup lang="ts">
import { ActionSheet, Cascader, type CascaderOption, Field, Popup, Tabbar, TabbarItem } from 'vant';
import { ref, watch } from 'vue';
import { useStyle3d } from '@repo/style3d';
import { useSelectionStore } from '@repo/stores';
import FabricScaleAngle from './fabric-scale-angle.vue';
import FabricMovement from './fabric-movement.vue';
import FabricColor from './fabric-color.vue';
import { useParamStore } from '@/store/param.ts';

const show = defineModel<boolean>('show');
const { selectMaterial, getMaterialList, getFabricState } = useStyle3d();

const selectionStore = useSelectionStore();
const paramStore = useParamStore();

const scale = ref(1);
const angle = ref(0);

watch(() => selectionStore.selectedFabricId, async (value) => {
  const list = await getMaterialList();
  fieldValue.value = list.find((f) => f.id === value)?.name || '';

  const { cycle = 1.0, rotation = 0 } = await getFabricState();
  angle.value = rotation;
  scale.value = cycle;
});

const options = ref<CascaderOption[]>([]);

watch([() => paramStore.recentUpdate, () => show.value], async ([_, show]) => {
  if (show) {
    const list = await getMaterialList();

    const patternOptions = Array.from(paramStore.patternTypeMap)
      .filter(([_, type]) => ['pattern'].includes(type))
      .map(([id, _]) => id).map(id => list.find(item => item.id === id))
      .filter(item => !!item)
      .map(item => ({ text: item!.name, value: item!.id }));

    const attachmentOptions = Array.from(paramStore.patternTypeMap)
      .filter(([_, type]) => ['button', 'buttonHole', 'zipperSlider', 'zipperPullTeeth', 'zipperPiece'].includes(type))
      .map(([id, _]) => id).map(id => list.find(item => item.id === id))
      .filter(item => !!item)
      .map(item => ({ text: item!.name, value: item!.id }));

    const otherOptions = Array.from(paramStore.patternTypeMap)
      .filter(([_, type]) => !['pattern', 'button', 'buttonHole', 'zipperSlider', 'zipperPullTeeth', 'zipperPiece'].includes(type))
      .map(([id, _]) => id).map(id => list.find(item => item.id === id))
      .filter(item => !!item)
      .map(item => ({ text: item!.name, value: item!.id }));

    options.value = [
      {
        text: '织物',
        value: '1',
        children: patternOptions
      },
      {
        text: '附件',
        value: '2',
        children: attachmentOptions
      },
      {
        text: '其它',
        value: '3',
        children: otherOptions
      }
    ];
  }
});

const tab = ref(0);

const fieldValue = ref('');
const showCascader = ref(false);
const cascaderValue = ref('1');


const onFinish = async ({ value }: { value: string }) => {
  showCascader.value = false;

  await selectMaterial(value, 400, true, 3);
};
</script>

<template>
  <ActionSheet v-model:show="show" title="编辑" :overlay="false" teleport="#app">
    <div :class="$style.content">
      <Field
        is-link
        readonly
        label="材质"
        v-model="fieldValue"
        placeholder="请选择材质"
        @click="showCascader = true"
      />
      <Popup v-model:show="showCascader" round position="bottom">
        <Cascader
          v-model="cascaderValue"
          title="请选择类型"
          :options="options"
          @close="show = false"
          @finish="onFinish"
        />
      </Popup>
      <FabricColor v-if="tab===0" />
      <div v-else-if="tab===1">
        <FabricMovement />
        <FabricScaleAngle v-model:scale="scale" v-model:angle="angle" />
      </div>
    </div>
    <Tabbar v-model="tab">
      <TabbarItem>颜色</TabbarItem>
      <TabbarItem>调整</TabbarItem>
    </Tabbar>
  </ActionSheet>
</template>

<style module>
.content {
  padding: 10px 30px 60px;
  display: flex;
  flex-direction: column;
}
</style>

