<script setup lang="ts">
import { Image, Loading, Swipe, SwipeItem } from 'vant';

import { computed, ref } from 'vue';
import { type QingflowFile } from '@/api/exhibition';
import { ImagePreview } from '@/components';

interface Props {
  height: number | string;
  fileList: (Omit<QingflowFile,'name'> & { type: 'image' | 'video' | 'unknown' })[];
}

const props = defineProps<Props>();

const imageContainer = ref<Element | null>(null);

const showPreview = ref(false);
const startPosition = ref(0);
const images = computed(() => props.fileList.map(item => item.url));

const onClickPreview = (index: number) => {
  startPosition.value = index;
  showPreview.value = true;
};
</script>

<template>
  <div
    ref="imageContainer"
    :style="{
      height: props.height+'px',
      width: '100%'
    }"
  >
    <Swipe lazy-render>
      <SwipeItem v-for="(file,index) of props.fileList" :key="file.url">
        <Image
          v-if="file.type==='image'"
          :src="file.url"
          :height="props.height"
          width="100%"
          :show-loading="true"
          :show-error="true"
          fit="contain"
          @click="onClickPreview(index)"
        >
          <template v-slot:loading>
            <Loading type="spinner" size="20" />
          </template>
        </Image>
        <video
          v-else-if="file.type==='video'"
          :style="{
            height: props.height+'px',
            width: '100%'
          }"
          preload="auto"
          controls
          :autoplay="false"
        >
          <source :src="file.url" type="video/mp4" />
        </video>
      </SwipeItem>
    </Swipe>

    <ImagePreview
      v-model:show="showPreview"
      :images="images"
      :startPosition="startPosition"
      :close-on-click-image="false"
      :closeable="true"
    />
  </div>
</template>

<style scoped>
:deep(.van-swipe__track) {
  align-items: center;
}
</style>
