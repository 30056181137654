// cloudknit面料
import axios from 'axios';
import { joinUrl } from '@repo/utils';
import { getAppConfig } from '@/utils/env.ts';

const { URL_PARSE_URL } = getAppConfig();

export const cloudKnitFabricReg = /^(?:https?:\/\/)?(?:[\w-]+\.)?cloudknit\.cn\/fabric\/([\w-]+)$/;

// 凌迪面料
export const style3dReg = /(?:https?:\/\/)?(?:[\w-]+\.)?style3d\.com\/fabric\/(\w+)/;
// 凌迪面料短链接
export const style3dUShortReg = /(?:https?:\/\/)?(?:[\w-]+\.)?style3d\.com\/(?:api_v2\/)?u\/(\w+)/;

export const validatorFabricUrl = (text: string) => {
  return cloudKnitFabricReg.test(text) ||
    style3dReg.test(text) ||
    style3dUShortReg.test(text);
};

interface FabricResource {
  id: string,
  type: 'cloudknit' | 'style3d'
}

export const processFabricProperty = async (url: string): Promise<FabricResource> => {
  if (cloudKnitFabricReg.test(url)) {
    const match = url.match(cloudKnitFabricReg);
    if (match && match[1]) {
      return handleCloudKnitFabricResource(match[1]);
    }
  } else if (style3dReg.test(url)) {
    return await handleStyle3dFabricResource(url);
  } else if (style3dUShortReg.test(url)) {
    const match = url.match(style3dUShortReg);
    if (match && match[1]) {
      const url = await parseStyle3dUShortUrl(match[1]);
      await handleStyle3dFabricResource(url);
    }
  }
  return Promise.reject();
};

const handleCloudKnitFabricResource = (id: string): FabricResource => {
  return { id, type: 'cloudknit' };
};

const handleStyle3dFabricResource = async (url: string): Promise<FabricResource> => {
  const regex = /fabric\/(\d+)(?:\?|\b)/;
  let id = url.match(regex)?.[1];
  try {
    const response = await axios.get<string>(joinUrl(URL_PARSE_URL, `/style3d/initial_data?url=${encodeURIComponent(url)}`));
    id = (response.data as any).m3dStore.detailData.extra?.renderData?.id;
  } catch (e: any) {
    console.log(e.message);
  }
  if (!id) {
    throw new Error('解析错误');
  }
  return { id, type: 'style3d' };
};

export const parseStyle3dUShortUrl = async (id: string) => {
  try {
    const response = await axios.get<string>(joinUrl(URL_PARSE_URL, `/style3d/u/${id}`));
    return response.data;
  } catch (error) {
    console.error('Error fetching the real URL:', error);
    throw new Error('解析错误');
  }
};
