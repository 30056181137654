<script setup lang="ts">
import { DropdownItem } from 'vant';
import { useOfficialStyle } from './use-official-style.ts';
import { watch } from 'vue';

const emit = defineEmits<{
  change: []
}>();
const { type1, type2, type3,onFilterChange, type1Options, type2Options, type3Options } = useOfficialStyle();

watch([type1, type2, type3], () => {
  emit('change');
}, {
  immediate: true
});
</script>

<template>
  <DropdownItem v-model="type1" :options="type1Options" @change="onFilterChange('type1')" />
  <DropdownItem v-model="type2" :options="type2Options" @change="onFilterChange('type2')" />
  <DropdownItem v-model="type3" :options="type3Options" @change="onFilterChange('type3')" />
</template>

<style scoped>

</style>
