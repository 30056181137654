<script setup lang="ts">
import { Logo, Style3dViewer } from '@repo/components';
import { useShareStore } from '@/store/share.ts';
import { storeToRefs } from '@repo/stores';
import { RecentList, Toolbar, Toolbar1 } from './components';
import { ToolbarContainer } from '@/components';

const shareStore = useShareStore();
const { logoUrl } = storeToRefs(shareStore);
</script>

<template>
  <div :class="$style.fabricPageContainer">
    <Logo :class="$style.logo" :logo-url="logoUrl" />
    <div :class="$style.style3d">
      <Style3dViewer />
    </div>
    <RecentList :class="$style.recentList" />
    <ToolbarContainer dark style="top: 10px;right: 10px;">
      <Toolbar1 />
    </ToolbarContainer>
    <ToolbarContainer dark style="bottom: 75px;right: 10px; padding-bottom: 10px;">
      <Toolbar />
    </ToolbarContainer>
  </div>

</template>

<style module>
.fabricPageContainer {
  background-color: #31363B;
  height: 100%;
  position: relative;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.style3d {
  background-color: #31363B;
  height: calc(100% - 80px);
}

.recentList {
  background-color: #31363B;
  position: absolute;
  bottom: 0;
  padding: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
}
</style>
