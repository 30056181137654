<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { type ExhibitionData, getExhibitionDataList } from '@/api/exhibition';
import DetailCard from './detail-card.vue';

const props = defineProps<{
  enterpriseId: string,
  externalId: string,
}>();

const exhibitionDataList = ref<ExhibitionData[]>([]);

const reload = async () => {
  exhibitionDataList.value = await getExhibitionDataList({
    enterpriseId: props.enterpriseId,
    externalId: props.externalId
  });
};
onMounted(async () => {
  await reload();
});

watch([() => props.enterpriseId, () => props.externalId], async () => {
  await reload();
});
</script>

<template>
  <div :class="$style.cardContainer">
    <DetailCard
      v-for="data of exhibitionDataList"
      :key="data.id"
      :data="data"
    />
  </div>
</template>

<style module>
.cardContainer {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
</style>
