import { defineStore } from '@repo/stores';
import { ref, watch } from 'vue';
import { type FabricStore, getRecentFabrics, saveRecentFabric } from '@/api';

export const useRecentFabricListStore = defineStore('recent-fabric-list', () => {
  const recentUpdate = ref<number>();
  const recentFabrics = ref<FabricStore[]>([]);

  const addRecentFabric = async (fabric: FabricStore) => {
    await saveRecentFabric(fabric);
    recentUpdate.value = Date.now();
  };

  watch(() => recentUpdate.value, async () => {
    recentFabrics.value = await getRecentFabrics(30);
  }, {
    immediate: true
  });

  return { recentUpdate, recentFabrics, addRecentFabric };
});
